<!-- Traning Item -->
<template>
    <div
    @mouseover="controlHover = true"
    @mouseleave="controlHover = false"
    class="raining-item cursor-pointer w-full relative border-2 p-8 h-full max-h-full hover:border-blue-principal">
        <div class="absolute -mt-14 right-10 flex gap-6">
            <div class="bg-blue-secondary p-3 flex items-center justify-center text-sm font-medium text-white">
                {{ data.isMixLearning ? $t('training.mixLearning') : $t('training.faceToTaceLearning') }}
            </div>
            <div class="bg-blue-thin p-3 flex items-center justify-center text-sm font-medium text-black">{{ $t('training.licence') }}</div>
        </div>
        <div class="content h-full flex flex-col justify-center">
       <h3 :class="[
        'text-2xl  font-semibold',
        controlHover ? 'text-blue-principal' : 'text-grey-real'
       ]"> {{ index }} .</h3> 
       <p class="mt-4 font-bold md:text-2xl text-xl text-red-principal capitalize "> {{ $t(`training.${data.label}`) }} </p>
       <div class="mt-4 flex text-black-principal font-medium text-sm">
        <h3 class="w-full">{{ data.isFullCertification ? $t('training.twoCertificate') : $t('training.oneCertificate') }}</h3>
        <div v-if="controlHover" class="flex w-full gap-3 justify-end items-center">
            <a class="text-black-principal font-medium text-sm">{{ $t("training.seeMore") }}</a>
            <span>&#8594;</span>
        </div>
       </div>
          
        </div>
    </div>
</template>

<script setup>
import { number } from '@intlify/core-base';
import { defineProps, ref } from 'vue';

// Define props
defineProps({
    index: number,
    data: {
        type: Object,
        default ()  {
            return { 
                label: "label",
                isFullCertification: false,
                isMixLearning: false
            }
        }
    }
});

// Control hover
const controlHover = ref(false);
</script>