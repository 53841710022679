<!-- Blog -->
<template>
<div class="blog mt-4 h-full">
    <div class="flex w-full md:flex-nowrap flex-wrap h-full">
        <!-- Blog section -->
        <div class="blog-items md:w-1/3 w-full pl-16 bg-red-principal pt-20 pb-20 h-full">
            <h3 class="text-4xl font-bold text-white">{{ $t('blog.title') }}</h3>
            <!-- Item of blog -->
            <div class="blog-items flex flex-col gap-6 mt-6"
               v-for="(item, index) in blogItems" :key="index">
                <BlogItem :data="item"></BlogItem>
            </div>
        </div>
        <!-- Current Article -->
        <div class="current-article max-h-full md:w-2/3 w-full bg-cover object-cover">
            <div class="content bg-blue-ultraLightn h-full flex flex-col justify-end p-8">
            <h3 class="text-white font-bold md:text-xl2 text-xl">Introduction To Bitcoin mining</h3>
            <div class="flex description-section mt-3 items-center gap-8">
                <div class="w-3/4 text-white md:text-base text-sm">Initial one-to-one consultation, Health & Fitness Assessments Bespoke training program planning, Custom Nutrition plan & recipes. Weekly Progress Reviews  </div>
                <a  href="/blog" class="font-semibold  md:text-lg text-xs action-button text-white cursor-pointer flex-grow flex items-center justify-center p-4 align-end bg-blue-principal">
                    {{ $t('blog.learnMore') }}
                    <span class="inline-block md:ml-3 ml-1">&#8594;</span>
                </a>
            </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import BlogItem from '../blog/BlogItem.vue';

// All blog items 
const blogItems = [
    {
        title: 'Harnessing the power of Bitcoin mining for diverse industries and investors, we transform underutilized energy into profitable computing power.',
        date: new Date('10-22-2021'),
    },
]


</script>

<style scoped>
  .current-article {
    background-image: url(../../assets/images/webp/slide_c.webp);
  }
</style>