<!-- President Word section -->
<template>
    <div class="presidente-word md:p-16 p-10">
        <!-- Text and Video section -->
        <div class="president-word-text flex pb-16">
            <div class="w-52 md:flex flex-col-reverse mr-10 items-end hidden">
                <div class="h-16 w-16 border-t-8 border-r-8 border-red-principal"></div>
            </div>
            <div class="w-full text-center">
                <!-- Text section -->
                <h3 class="font-bold text-xsemiultra text-blue-principal">{{ $t('president.title') }}</h3>
                <p class="descripiton-content font-normal text-xl mt-5 leading-8 text-grey-principal md:pl-6 md:pr-6">
                    {{ $t('president.description') }}
                </p>
                <router-link class="mt-4 font-bold text-lg block" to="/about">{{ $t('blog.learnMore') }} </router-link>
                <span class="w-14 h-1 bg-grey-thin inline-block"></span>
                <!-- Video section -->
                <div class="mt-16 relative w-full">
                    <!-- Dot section -->
                    <div class="dot-section  md:flex hidden h-96 w-full  flex-wrap">
                        <span
                        v-for="(item, index) in 784" :key="index" 
                        class="w-2 h-2 m-2 rounded-full bg-grey-light">
                        <div class="bg_blog"></div>
                        
                    </span>
                    </div>
                    <div class="dot-section  md:hidden flex h-96 w-full -ml-8  flex-wrap">
                        <span
                        v-for="(item, index) in 200" :key="index" 
                        class="w-2 h-2 m-2 rounded-full bg-grey-light"></span>
                    </div>
                    <!-- Video -->
                    <div class="video absolute top-0 right-0 w-full">
                        <div class="mt-16 md:ml-24 h-96 bg-red-principal relative">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/GmOzih6I1zs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-52 md:block hidden">
                <div class="h-16 w-16 border-t-8 border-l-8 border-red-principal"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>
