<!-- Blog Item -->
<template>
    <div class="blog-item w-full pl-4 pt-2 pb-2 pr-4 border-l-4 border-black-thin cursor-pointer hover:border-white">
       <h3 class="font-bold text-white md:text-x22 text-xl"> {{ data.title }} </h3> 
       <p class="mt-1 text-grey-white text-xs">{{ data.description }}</p>   
       <h3 class="mt-3 text-grey-white text-xs font-light capitalize">{{  moment(props.data.date).locale('fr').tz('Africa/Porto-Novo').format('DD MMMM yyyy').toString() }}</h3>
    </div>
</template>

<script setup>
import { defineProps } from 'vue'
import * as moment from 'moment-timezone'

// Props 
const props = defineProps({
    data: {
        type: Object,
        default () {
            return {
                title: '#HackTonCrane 2022, nous y sommes',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus et eu neque feugiat pellentesque placerat nullam.',
                date: new Date()
            }
        }
    }
})

</script>