<!-- Carrer Section -->
<template>
    <div class="carrer-section md:p-16 p-10">
        <!-- Carrer section block -->
        <div class="carrer-section flex w-full md:flex-row flex-col md:space-x-20">
            <!-- Image section -->
            <div class="flex-one md:w-1/2 w-full p-4 flex items-center justify-center">
                <img  src="../../assets/images/webp/img_carrer_man.webp"/>
            </div>
            <!-- Details section  -->
            <div class="flex-one md:w-1/2  w-full flex items-center">
                 <div>
                    <h3 class="font-bold mt-1 text-blue-principal md:text-left text-center text-xsemiultra">{{ $t('carrer.title') }}</h3>
                 <div class="mt-10">
                    <p class="text-xl md:text-left text-center  leading-relaxed text-grey-veryThin font-normal">{{ $t('carrer.description') }}</p>
                 </div>
                 <div class="mt-16">
                    <!-- Action button  -->
                <div class="flex md:gap-16 gap-10 md:flex-row flex-col">
                <XButton 
                @click="showDocs"
                :title="$t('button.candidateDocument')"
                xstyle="border-2 border-blue-principal text-white h-14 text-blue-principal text-lg pl-8 pr-8 pt-4 pb-4"
                />
                <XButton 
                :title="$t('button.candidateNow')"
                @click="goSign"
                xstyle="bg-red-principal text-white h-14 text-bold text-lg pl-8 pr-8 pt-4 pb-4"
                />
              </div>
                 </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import XButton from '../globals/XButton.vue';

import { useRouter } from 'vue-router';
import { defineEmits } from 'vue';

const router = useRouter();

const emit = defineEmits(['docs']);

function goSign() {
    router.push('/inscription');
}

function showDocs() {
  emit('docs', true)
}
</script>
