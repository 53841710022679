<template>
  <div class="slider h-screen">
    <Carousel class="h-screen max-h-screen" v-slot="{currentSlide}">
        <Slide v-for="(slide, index) in carousel_slides" :key="index">
            <div 
            v-show="currentSlide === index + 1"
            class="content absolute w-full max-w-full">
                <img class="min-w-full md:h-screen h-screen object-cover" :src="require('../../assets/images/webp/slide_' + slide + '.webp')">
            </div>
        </Slide>
    </Carousel>
  </div>
</template>

<script setup>
import Carousel from '../carousel/Carousel.vue';
import Slide from '../carousel/Slide.vue';
import { ref } from 'vue';

// Carousel slide
const carousel_slides = ref(['c', 'b', 'd','a' ]);

</script>

<style  scoped>

</style>>