<!-- Contact form section -->

<template>
    <div class="contact-form-section md:p-16 p-10">
        <div class=" -mt-16 icon-section absolute">
            <img class="h-32 w-32" src="../../assets/images/webp/ic_move_arrow.webp">
        </div>
        <div class="header-section">
            <span class="w-10 h-1 bg-blue-principal inline-block"></span>
            <h3 class="font-semibold text-blue-light text-sm mt-1">{{ $t('contact.contactUsNow') }}</h3>
            <h3 class="font-bold mt-1 text-blue-principal text-xsemiultra">{{ $t('contact.askYourQuestionNow') }}</h3>
        </div>
        <div class="contact-form-section flex mt-12 md:space-x-24 md:flex-row flex-col space-y-10">
            <!-- Contact information section -->
            <div class="flex-one w-full md:w-1/2">
                <p class="text-xl  leading-relaxed text-grey-veryThin font-normal">
                    {{  $t('contact.descriptionLorem') }}
                </p>
                <div class="social-items mt-12 flex space-x-10">
                    <div class="flex-one">
                        <span class="text-grey-principal">{{  $t('topHeader.tel') }}</span><br/>
                        <span class="text-blue-principal font-medium md:text-xl">+221 33 843 87 15</span>
                    </div>
                      <div class="flex-one">
                        <span class="text-grey-principal ">{{  $t('topHeader.email') }}</span><br/>
                        <span class="text-blue-principal font-medium md:text-xl">info@eclipse.holding</span>
                    </div>

                </div>
            </div>

            <!-- Contact form section -->
            <div class="contact-form md:w-1/2 w-full">
                <div class="name-input h-16 bg-hensa-form">
                    <input :placeholder="$t('contact.fullname')" class="w-full p-4 h-full bg-transparent"/>
                </div>
                <div class="mt-8 h-16">
                    <!-- <Multiselect
                        v-model="value"
                        class="multiselect h-16 bg-hensa-form border-0"
                        :placeholder="$t('contact.whatYouWant')"
                        :options="options" /> -->
                        <select v-model="value" class="w-full h-full  p-4 bg-hensa-form">
                            <option>{{ $t('contact.options.details') }}</option>
                            <option>{{ $t('contact.options.trainingProgram') }}</option>
                            <option>{{ $t('contact.options.workWithUs') }}</option>
                            <option>{{ $t('contact.options.professionnalTranning') }}</option>
                        </select>
                </div>
                <div class="mt-8 w-full  bg-hensa-form">
                    <textarea
                    rows="8"
                    :placeholder="$t('contact.yourMessageHere')"
                    class="w-full bg-transparent h-full outline-none p-4"
                    ></textarea>
                </div>
                <div class="mt-8 send-button-section md:w-1/5 w-1/3">
                    <XButton :title="$t('contact.send')" xstyle="bg-blue-principal p-4 text-white font-medium"></XButton>
                </div>
            </div>
             
        </div>
    </div>
</template>


<script setup>
import { ref } from 'vue';
// import { useI18n } from 'vue-i18n';
import XButton from '../globals/XButton.vue';

 // const { t } = useI18n();


// Result value 
const value = ref('')
// const options = [
//     t('contact.options.details'),
//     t('contact.options.trainingProgram'),
//     t('contact.options.workWithUs'),
//     t('contact.options.professionnalTranning'),
    
// ]

</script>
<style scoped>
.multiselect {
    background-color: #F8FAFD;
    border: none !important;
}
.multiselect__option--highlight {
    background-color: red !important;
}  
.multiselect:deep(.multiselect-option .is-selected)  {
background-color: red !important;
}
</style>
