<!-- Discover block -->
<template>
    <div class="discover_block text-center md:p-16 p-10 flex justify-center">
        <div class="content text-center md:w-3/5 w-full">
        <span class="h-1 w-14 bg-blue-principal inline-block"></span>
        <h3 class="uppercase mt-1 font-semibold text-sm text-blue-light">{{ $t('discover.subtitle') }}</h3>
        <h1 class="font-bold mt-6 text-xsemiultra text-blue-principal"> {{ $t('discover.title') }} </h1>
        <p class="description font-normal text-xl mt-5 leading-8 text-grey-principal md:pl-6 md:pr-6">  {{ $t('discover.description') }} </p>
        </div>
    </div>
</template>

<script>

</script>
