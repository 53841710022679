<!-- Button component -->
<template>
    <div :class="[
        'x-button flex items-center justify-center cursor-pointer',
        props.xstyle
    ]">
    {{  props.title }}
    </div>
</template>

<script setup>
import { defineProps } from 'vue'

// Component props 
const props = defineProps({
    // Button name
    title: {
        type: String,
        required: true,
    },
    // Button custom style 
    xstyle: {
        type: String,
        required: false,
    }
})

</script>