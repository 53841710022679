<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="carousel md:h-screen h-screen">
        <OptionPicker v-if="showDoc" @close="showDoc = false"></OptionPicker>
        <slot :currentSlide="currentSlide" />
        <!-- Pagination and Navigation -->
        <div class="pagination absolute flex bottom-4 w-full justify-center items-center gap-4">
            <span v-for="(slide, index) in getAllSlidesCount" :key="index" @click="goToSlide(index + 1)" :class="[
                'cursor-pointer',
                index === currentSlide - 1 ? 'w-10 h-3 bg-transparent rounded-xl border-white border-2' : 'w-3 h-3 rounded-full bg-white'
            ]"></span>
        </div>

        <!-- Slide text section -->
        <div class="typing-text-section mt-10 absolute h-screen md:w-full flex justify-center items-center">

            <div class="content-section md:p-0">

                <!-- Typing block  -->

                <p
                    class="title-section text-white mx-8 justify-center font-semibold md:text-xultra md:leading-0 leading-normal	text-5xl md:text-left text-center">
                    <VueWriter :array="array" :eraseSpeed="50" :typeSpeed="100" :iterations="1" />
                </p>

                <!-- Now Block-->

                <div class="flex items-center md:mt-10 mt-6 justify-center w-full">
                    <div class="pl-4 pr-3 h-12 bg-red-principal uppercase">
                        <p class="text-white md:-mt-12  -mt-4 font-extrabold md:text-xultra text-5xl">{{
                                $t('slider.now')
                        }}</p>
                    </div>
                </div>

                <!-- Actions button section -->
                <div class="flex md:flex-row flex-col md:p-0 pl-10 pr-10 justify-center mt-32 gap-16">
                    <XButton
                    @click="showDoc = true"
                     :title="$t('button.candidateDocument')"
                        xstyle="border-2 border-white text-white h-14 text-bold text-lg pl-8 pr-8 pt-4 pb-4" />
                    <XButton  :title="$t('button.candidateNow')"
                    @click="goSign"
                        xstyle="bg-white text-black h-14 text-bold text-lg pl-8 pr-8 pt-4 pb-4" />
                </div>
            </div>
        </div>

        <!-- Top header Section -->
        <div
            class="top-header md:flex hidden absolute bg-blue-principal w-full items-center justify-start h-11 pl-16 pr-16">
            <div class="email-and-phone text-xs w-full flex gap-10 text-grey-white font-thin">
                <div class="flex gap-3">
                    <p> {{ $t('topHeader.tel') }}</p>
                    <p>+221 33 843 87 15</p>
                </div>
                <div class="flex gap-3">
                    <p> {{ $t('topHeader.email') }}</p>
                    <p>contact@eclips.holding</p>
                </div>
            </div>
            <div class="login-section text-xs text-grey-white font-thin cursor-pointer">
                {{ $t('topHeader.login') }}
            </div>
        </div>

        <!-- Menu ml-72 -->
        <div class="absolute menu mt-x39 ml-72" v-if="showMenu" @mouseleave="showMenuPlease(false);">
        <div class="content flex">
      <!-- Menu items  -->
            <div :class="[
                'flex flex-col gap-4 bg-white z-99',
             ]">
                <div class="flex-one flex pl-8 pt-4 pr-8 pb-2 cursor-pointer spax items-center focus-within:text-xl"
                @mouseover="showTranningPlease(true)"
            >
                    <div class="w-full font-medium text-xl pr-10">Formations</div>
                    <div class=" text-3xl transform rotate-270">
                        <icon :data="icon_m" height="16" width="16" original />
                    </div>
                </div>
                <div class="flex-one flex pl-8 pt-2 pr-8 pb-2 cursor-pointer items-center focus-within:text-xl"
                                @mouseover="showTranningPlease(false)"
>
                    <div class="w-full font-medium text-xl">
                        <a href="#presidentWord" class="text-black-principal"> {{ $t('menu.presidentWord') }}</a>
                    </div>
                </div>
                <div class="flex-one flex pl-8 pt-2 pr-8 pb-2 cursor-pointer items-center focus-within:text-xl">
                         <div class="w-full font-medium text-xl">
                        <a href="#contact" class="text-black-principal"> {{ $t('menu.contact') }}</a>
                    </div>
                </div>
                <div
                @mouseover="showAdmissionPlease(flase)"
                 class="flex-one flex pl-8 pt-2 pr-8 pb-2 cursor-pointer items-center focus-within:text-xl">
                    <div class="w-full font-medium text-xl">
                         <a href="#blog" class="text-black-principal"> {{ $t('blog.title') }}</a>
                    </div>
                </div>
                <div
                @mouseover="showAdmissionPlease(true)"
                 class="flex-one flex pl-8 pt-2 pr-8 pb-4 cursor-pointer items-center focus-within:text-xl">
                    <div class="w-full font-medium text-xl">Admission</div>
                    <div class=" text-3xl transform rotate-270">
                        <icon :data="icon_m" height="16" width="16" original />
                    </div>
                </div>
            </div>

            <!-- Formation liste -->
            <div v-if="showTrannig" @mouseleave="showTranningPlease(false)" class="formation flex-grow">
                <div class="class-item flex flex-wrap bg-white">
                    <div class="w-1/2 cursor-pointer p-4">
                        <span @click="goDetail('digitalProjectManager')" class="text-black-principal inline-block">{{ $t('training.digitalProjectManager') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span @click="goDetail('srit')" class="text-black-principal inline-block">{{ $t('training.srit') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span @click="goDetail('webDevelopper')" class="text-black-principal inline-block">{{ $t('training.webDevelopper') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span @click="goDetail('bfa')" class="text-black-principal inline-block">{{ $t('training.bfa') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span @click="goDetail('ecom')" class="text-black-principal inline-block">{{ $t('training.ecom') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span @click="goDetail('audit')"  class="text-black-principal inline-block">{{ $t('training.audit') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span @click="goDetail('itTech')"  class="text-black-principal inline-block">{{ $t('training.itTech') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span @click="goDetail('projectManagement')" class="text-black-principal inline-block">{{ $t('training.projectManagement') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer">
                        <span  @click="goDetail('twin')" class="text-black-principal inline-block">{{ $t('training.twin') }}</span>
                    </div>
                    <div class="w-1/2 p-4 cursor-pointer invisible">
                        <a class="text-black-principal">{{ $t('training.digitalProjectManager') }}</a>
                    </div>
                </div>
            </div>
            <!-- Formation liste -->
            <div @mouseleave="showAdmissionPlease(false)" v-if="showAdmission" class="admission-details  text-white">
                <div class="content flex flex-col mt-x37  justify-center bg-white">
                    <div class="cursor-pointer p-4">
                        <!-- <a href="inscription" class="text-black-principal">{{ $t('button.candidateNow') }}</a> -->
                    </div>
                    <div class=" p-4 cursor-pointer">
                        <a href="#carrer" class="text-black-principal">{{ $t('button.candidateDocument') }}</a>
                    </div>
                    <div class="p-4 cursor-pointer">
                        <!-- <a  href="https://firebasestorage.googleapis.com/v0/b/rema-da2f4.appspot.com/o/brochure.pdf?alt=media&token=bf685015-b301-48e0-babf-0de37515cee5" class="text-black-principal" target="_blank">{{ $t('button.brochure') }}</a> -->
                    </div>
                </div>
            </div>
        </div>
        </div>

        <!-- Header Section -->
        <div
            class="header-section md:h-28 h-24 md:mt-11 absolute w-full bg-red-light flex items-center md:pl-16 md:pr-16 pl-10 pr-10 pt-10 pb-10">
            <div class="flex flex-grow md:gap-10 gap-4 items-center">
                <a class="image-section block" href="/">
                    <img class="md:h-16 h-8" src="../../assets/images/webp/logo.webp" />
                </a>
                <div class="w-px md:h-16 h-10 bg-grey-white"></div>
                <div class="burger-section flex flex-col md:gap-3 gap-2 cursor-pointer" @click="showMenuPlease(true)">
                    <div class="h-1 md:w-12 w-10 bg-white"></div>
                    <div class="h-1 md:w-12 w-10 bg-white"></div>
                    <div class="h-1 md:w-12 w-10 bg-white"></div>
                </div>

            </div>
            <div class="contact-us text-white font-medium cursor-pointer text-xl">
               <a href="#contact"> {{ $t('header.contactUs') }}</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import XButton from '../globals/XButton.vue';
import icon_m from '../../assets/icons/ic_drop_arrow.svg';
import { useRouter } from 'vue-router';
import OptionPicker from '../globals/OptionPicker.vue';
import { useStore } from 'vuex';
 
const store = useStore();

const router = useRouter();

const { t } = useI18n();



const showMenu = ref(false);
const showTrannig = ref(false);
const showAdmission = ref(false);
const showDoc = ref(false)

// Writter text array 
const array = [t('slider.welcomeInfutureSchool')]

// Configure autoplay
const autoPlayEnabled = ref(true);
const timeoutDuration = ref(3000);

// Current slide item;
const currentSlide = ref(1)

// All slide items
const getAllSlidesCount = ref(null);

onMounted(() => {
    // Get all element who have slide class
    getAllSlidesCount.value = document.querySelectorAll('.slide').length;
})

// Go to chose slide
function goToSlide(position) {
    currentSlide.value = position;
}

// Auto play 
function autoPlay() {
    setInterval(() => {
        moveSlide()
    }, timeoutDuration.value)
}

// Auto play slide
if (autoPlayEnabled.value) {
    autoPlay();
}



// Move next or back
function moveSlide() {
    if (currentSlide.value === getAllSlidesCount.value) {
        currentSlide.value = 1
        return;
    }
    currentSlide.value++;
}

function showTranningPlease(value) {
    showTrannig.value = value;
}

function showMenuPlease(value) {
    showMenu.value = value;
}

function showAdmissionPlease(value) {
    showAdmission.value = value;
}

function goSign() {
    router.push('/inscription');
}

function goDetail(item) {
   store.dispatch('saveTrainning', item)
   router.push('details')
}

</script>

<style scoped>
.header-option {
    margin-left: 16.4%;
}

.menu {
    z-index: 999 !important;
}
</style>

