<!-- Gallery  -->
<template>
    <div @mouseover="showAction=true"
         @mouseleave="showAction=false" class="gallery-block relative">
         <!-- hover item -->
        <div  v-if="showAction" class="absolute h-full bg-blue-veryLight w-full flex items-center justify-center">
             <!-- Actions button section -->
              <div class="flex md:flex-row flex-col justify-center md:gap-16 gap-10">
                <XButton 
                @click="showDocs"
                :title="$t('button.candidateDocument')"
                xstyle="border-2 border-white text-white h-14 text-bold text-lg pl-8 pr-8 pt-4 pb-4"
                />
                <XButton 
                :title="$t('button.candidateNow')"
                @click="goSign"
                xstyle="bg-white text-black h-14 text-bold text-lg pl-8 pr-8 pt-4 pb-4"
                />
              </div>
        </div>
        <!-- Gallery Section -->
        <div class="gallery-section flex gap-0 flex-wrap w-full">
            <div
            v-for="(image, index) in images"
            :key="index"
            :style="{'background-image': 'url(' + require('../../assets/images/webp/galery_' + image + '.webp') + ')'}"
            class="image-item md:h-80 h-40  md:w-1/3 w-1/2 bg-cover">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import XButton from '../globals/XButton.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const emit = defineEmits(['docs']);

// Gallery images
  const images = ref(['a', 'b', 'c', 'd', 'five', 'six'])

// Show hover container 
const showAction = ref(false) 

function goSign() {
    router.push('/inscription');
}

function showDocs() {
  emit('docs', true)
}
</script>