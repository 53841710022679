<!-- Avantages Section -->
<template>
<div class="avantages-section">
    <!-- Avantages Icons Section -->
   <div class="avantages-container flex md:items-start items-center justify-center md:pt-16 md:pb-16 pt-10 pb-10 flex-wrap">
    <div v-for="(icon, index) in icons" :key="index" class="md:w-1/4 w-1/2 flex items-center justify-center">
        <div class="content text-center flex flex-col items-center md:p-0 p-4">
            <icon :data="icon.icon" height="76" width="50" original/>
            <h3 class="font-semibold text-grey-principal md:text-xl md:w-2/3 w-full"> {{ icon.label }} </h3>
        </div>
    </div>
   </div>

   <!-- Candidate Option -->
   <div 
       class="bg-red-principal md:pt-10 md:pb-10  p-5  flex items-center"> 
       <!-- Brochure section -->
       <div
        @mouseover="hover_one = true"
        @mouseleave="hover_one = false"
        class="w-1/3 brochure  h-32 flex justify-center items-center border-r border-red-thin   text-left ">
         <div class="content -mt-8">
            <h3 class="md:text-xl2 font-bold text-white">
            {{ $t('avantages.brochure.title') }}
          </h3>
          <h3 
            :class="[
            'md:text-xl2 font-bold z-50 absolute',
            hover_one ? 'text-black' : 'text-white'

            ]"
         > {{ $t('avantages.brochure.subtitle') }}</h3>  
          <span 
          :class="[
            'inline-block z-10 absolute md:mt-4 ml-4  h-6 md:w-20 w-10',
            hover_one ? 'bg-yellow-principal' : 'bg-blue-principal'
          ]"
         >
          </span>
         </div>
       </div>

       <!-- Document section -->
         <div
             @mouseover="hover_two = true"
              @mouseleave="hover_two = false"
          class="w-1/3 brochure flex justify-center  h-32 items-center border-r border-red-thin text-left ">
         <div class="content -mt-8">
            <h3 class="md:text-xl2 font-bold text-white">
            {{ $t('avantages.document.title') }}
          </h3>
          <h3 
          :class="[
            'md:text-xl2 font-bold z-50 absolute',
            hover_two ? 'text-black' : 'text-white'

            ]"
          > {{ $t('avantages.document.subtitle') }}</h3>  
          <span 
          :class="[
            'inline-block z-10 absolute md:mt-4 ml-4  h-6 md:w-36 w-16',
            hover_two ? 'bg-yellow-principal' : 'bg-blue-principal'
          ]"
          >
          </span>
         </div>
       </div>

       <!-- Candidate Section  -->
        <div
        @mouseover="hover_three = true"
        @mouseleave="hover_three = false"
          class="w-1/3 brochure flex   h-32 justify-center items-center text-left ">
         <div class="content -mt-8">
            <h3 class="md:text-xl2 font-bold text-white">
            {{ $t('avantages.candidate.title') }}
          </h3>
          <h3 :class="[
            'md:text-xl2 font-bold z-50 absolute',
            hover_three ? 'text-black' : 'text-white'

            ]"> {{ $t('avantages.candidate.subtitle') }}</h3>  
          <span 
          :class="[
            'inline-block z-10 absolute md:mt-4 ml-4  h-6 md:w-20 w-10',
            hover_three ? 'bg-yellow-principal' : 'bg-blue-principal'
          ]">
          </span>
         </div>
       </div>
    </div>

   <!-- Avantages Image and Point -->
   <div class="pt-20 pb-4 w-full md:block hidden"> 
       <div class="content-container h-xxl w-full object-cover bg-cover	bg-center pl-32">
        <div class="w-1/4 h-full bg-blue-hensa flex flex-col p-6 justify-center gap-14">
            <h3 class="text-xl font-bold text-white">{{ $t('avantages.option') }}</h3>
            <h3 class="text-xl font-bold text-white">{{ $t('avantages.campus') }}</h3>
            <h3 class="text-xl font-bold text-white">{{ $t('avantages.percent') }}</h3>

        </div>
       </div>
    </div>
</div>
</template>

<script setup>
import ic_work from '../../assets/icons/ic_work.svg'
import ic_students from '../../assets/icons/ic_students.svg'
import ic_teachers from '../../assets/icons/ic_teachers.svg'
import ic_certifications from '../../assets/icons/ic_certif.svg'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'


const { t } = useI18n();
 
// Icons List
const icons = ref([
      { 
        icon: ic_students,
        label: t('avantages.student'),
    },
      { icon: ic_teachers,
        label: t('avantages.teacher') ,

    },
     { icon: ic_certifications,
        label: t('avantages.certif'),
      },

        { icon: ic_work,
        label: t('avantages.work'),
    }
])

// Step hover controle
const hover_one = ref(false);
const hover_two = ref(false);
const hover_three = ref(false);

</script>


<style scoped>
  .content-container  {
    background-image: url(../../assets/images/webp/bg_one.webp);
  }
</style>