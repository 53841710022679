<template>
  <div class="home">
    <OptionPicker v-if="showDoc" @close="showDoc = false"></OptionPicker>
    <Slider></Slider>
    <div class="president-word-section mt-11" id="presidentWord">
      <PresidentWord></PresidentWord>
    </div>
    <div class="discover-section">
      <Discover></Discover>
    </div>
    <div class="gallery-section">
      <Gallery @docs="showDoc = true"></Gallery>
    </div>
    <div class="avantages-section">
      <Avantages></Avantages>
    </div>
    <div class="traning-section">
      <Training></Training>
    </div>
    <div class="blog-section" id="blog">
      <Blog></Blog>
    </div>
    <div class="contact-section" id="contact">
      <Contact></Contact>
    </div>
    <div class="carrer-section" id="carrer">
      <Carrer @docs="showDoc = true"></Carrer>
    </div>
    <div class="footer">
      <Footer @shoWOption="showDoc = true"></Footer>
    </div>
  </div>
</template>

<script setup>
import Slider from '../../components/Items/Slider.vue'
import PresidentWord from '../../components/Items/PresidentWord.vue';
import Discover from '../../components/Items/Discover.vue';
import Gallery from '../../components/Items/Gallery.vue';
import Avantages from '../../components/Items/Avantages.vue';
import Training from '../../components/Items/Training.vue';
import Blog from '../../components/Items/Blog.vue';
import Contact from '../../components/Items/Contact.vue';
import Carrer from '../../components/Items/Carrer.vue';
import Footer from '../../components/globals/Footer.vue';
import OptionPicker from '../../components/globals/OptionPicker.vue';
import { ref } from 'vue';

const showDoc = ref(false)

</script>

<style scoped>

</style>
