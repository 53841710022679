<!-- Training  -->
<template>
    <div 
   class="training text-black md:p-16 p-10">
        <!-- Header -->
        <span class="w-10 h-1 bg-blue-principal inline-block"></span>
        <h3 class="font-semibold text-blue-light text-sm mt-1">{{ $t('training.subtitle') }}</h3>
        <h3 class="font-bold mt-2 text-blue-principal text-xsemiultra">{{ $t('training.ourTraining') }}</h3>

        <!-- Option Item -->
        <div class="option-list-section flex md:flex-row flex-col flex-wrap gap-x-8  gap-y-10 mt-14"> 
            <div 
            class="md:w-x32"
            v-for="(item, index) in data" 
            :key="index"
            @click="goDetail(item)"
            >
                <TrainingItem :data="item" :index="index+1"></TrainingItem>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import TrainingItem from '../tranning/TrainingItem.vue';
import { useStore } from 'vuex';
 
const router = useRouter()
const store = useStore();


const data = ref([
     {
        label: 'digitalProjectManager',
        isFullCertification: true,
        isMixLearning: false
     },
          {
        label: "webDevelopper",
        isFullCertification: true,
        isMixLearning: false
     },
          {
        label: "ecom",
        isFullCertification: true,
        isMixLearning: true
     },
     /*      {
        label: "itTech",
        isFullCertification: false,
        isMixLearning: false
     },
          {
        label: "twin",
        isFullCertification: false,
        isMixLearning: false
     },
          {
        label: "srit",
        isFullCertification: false,
        isMixLearning: false
     },
     {
        label: "bfa",
        isFullCertification: false,
        isMixLearning: false
     },
          {
        label: "audit",
        isFullCertification: false,
        isMixLearning: false
     },
          {
        label: "projectManagement",
        isFullCertification: false,
        isMixLearning: false
     } */
])

function goDetail(item) {
   store.dispatch('saveTrainning', item.label)
   router.push('details')
}
</script>
